"use client";

import { cn } from "@/lib/utils";
import clsx from "clsx";
import { Loader } from "lucide-react";
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  JSXElementConstructor
} from "react";
import { twMerge } from "tailwind-merge";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  className?: string;
  variant?: "solid" | "outlined" | "text";
  size?: "default" | "small";
  active?: boolean;
  type?: "submit" | "reset" | "button";
  width?: string | number;
  loading?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  Component?: string | JSXElementConstructor<any>;
}

// eslint-disable-next-line react/display-name
const Button: React.FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = "solid",
    children,
    active,
    width,
    loading = false,
    disabled = false,
    Component = "button",
    style = {},
    icon,
    size,
    "aria-label": aria_label,
    "aria-disabled": aria_disabled,
    ...rest
  } = props;

  const solid =
    "bg-primaryGON-300 text-primaryGON-50 rounded-full flex w-full cursor-pointer hover:bg-primaryGON-500 focus-visible:bg-primaryGON-600";

  const text =
    "bg-none text-primaryGON-300 rounded-full flex w-full cursor-pointer hover:bg-primaryGON-300 hover:text-primaryGON-50 focus-visible:bg-primaryGON-500";

  const outlined = `${size == "small" ? "ring-1" : "ring-2"} ring-primaryGON-300 text-primaryGON-300 rounded-full flex w-full cursor-pointer hover:bg-primaryGON-300 hover:text-primaryGON-50 focus-visible:bg-primaryGON-500`;

  const styles =
    variant == "solid" ? solid : variant == "text" ? text : outlined;

  const Icons = () =>
    loading ? (
      <Loader size={size == "small" ? 16 : 24} className="animate-spin" />
    ) : (
      icon || null
    );

  return (
    <Component
      className={twMerge(
        "transition-all duration-500 delay-75",
        size == "small"
          ? "py-2 px-3"
          : "@md:py-3 @md:pl-4 @md:pr-3 py-2 pl-3 pr-2",
        styles,
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        className
      )}
      disabled={disabled}
      aria-label={aria_label}
      aria-disabled={aria_disabled}
      {...rest}
    >
      <div className="flex flex-row items-center gap-3 w-full justify-center">
        <Icons />
        <div
          className={clsx(
            "select-none font-poppins font-medium uppercase truncate",
            size == "small" ? "text-xs font-normal" : "text-base"
          )}
        >
          {children}
        </div>
      </div>
    </Component>
  );
});

export default Button;
