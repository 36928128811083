"use client";

import currency from "@/functions/currency";
import { Rounded } from "@/public/svg";
import clsx from "clsx";
import { ArrowUpRightIcon, ChevronDownIcon } from "lucide-react";
import React, { ButtonHTMLAttributes } from "react";

interface position {
  width: "half" | "full";
}
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  className?: string;
  variant?: "solid" | "outlined" | "text";
  active?: boolean;
  type?: "submit" | "reset" | "button";
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
  label?: position;
  title: string;
  price: number;
}

export default function ButtonCustom(props: ButtonProps) {
  const {
    className,
    variant = "solid",
    active,
    width,
    loading = false,
    disabled = false,
    style = {},
    label = { width: "full" },
    title,
    price,
    ...rest
  } = props;

  return (
    <button
      disabled={disabled || false}
      className={clsx(
        "@md:py-3 @md:pl-4 @md:pr-3 py-2 pl-4 pr-2 bg-primaryGON-300 rounded-[50px] flex flex-row w-full justify-between space-x-1 items-center cursor-pointer hover:bg-primaryGON-500 focus:bg-primaryGON-600 transition-all duration-500 delay-100",
        label.width == "half" ? "@lg:space-x-2" : "@lg:space-x-7"
      )}
      {...rest}
    >
      <p className="select-none font-poppins font-semibold text-xs truncate text-primaryGON-50">
        {title}
      </p>
      <div className="py-2 px-3 bg-primaryGON-50 rounded-full">
        <p className="select-none font-poppins font-semibold text-xs text-greyscaleGON-800">
          {currency(price || 0)}
        </p>
      </div>
    </button>
  );
}

export const ButtonCustomTedd = (props: ButtonProps) => {
  const { label = { width: "full" } } = props;
  return (
    <>
      <div
        className={clsx(
          "absolute transition-all delay-75 bottom-0 text-white right-0 "
        )}
      >
        <Rounded className="w-6 h-6 right-0 absolute rotate-180 -top-6" />
        <div className="pl-[8px] pt-[4px] bg-white rounded-tl-[30px]">
          <div className="ml-[0px] mt-[4px] bg-primaryGON-300 rounded-full text-primaryGON-50">
            <div className="@md:p-4 p-3 group-hover:rotate-45 duration-300 transition-all">
              <ArrowUpRightIcon className="@md:w-6 @md:h-6 w-4 h-4" />
            </div>
          </div>
        </div>
        <Rounded className="w-6 h-6 -left-6  absolute rotate-180 bottom-0" />
      </div>
      {/* <div
        className={clsx(
          "absolute bottom-0  transition-all delay-75 text-white right-0 @3xl:left-1/2 @md:left-1/4 @sm:left-12 left-1",
          label.width == "full" ? "@3xl:left-1/2" : "@3xl:left-8"
        )}
      >
        <Rounded className="w-6 h-6 right-0  absolute rotate-180 -top-6" />
        <div className="pl-[8px] pt-[4px] bg-white rounded-tl-[30px]">
          <div className="ml-[0px] mt-[4px]">
            <ButtonCustom {...props} />
          </div>
        </div>
        <Rounded className="w-6 h-6 -left-6  absolute rotate-180 bottom-0" />
      </div> */}
    </>
  );
};
